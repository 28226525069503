import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './medicio/style.scss'
import { Index } from './react/Index'
import reportWebVitals from './reportWebVitals'
import { render } from 'react-snapshot'

render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
